import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import Home from './pages/Home';
import Version1 from './pages/Version1';
import Version2 from './pages/Version2';
import Notify from './pages/Notify';
import './App.css';

const { Header, Content } = Layout;

const menuItems = [
  { label: <Link to="/">Home</Link>, key: 'home' },
  { label: <Link to="/version1">Version 1</Link>, key: 'version1' },
  { label: <Link to="/version2">Version 2</Link>, key: 'version2' },
  //{ label: <Link to="/notify">Notify me</Link>, key: 'notifyMe' },
  //{ label: <Link to="/about">About</Link>, key: 'about' },
];

function App() {
  return (
    <Router>
      <Layout>
        <Header>
          <Menu theme="dark" mode="horizontal" items={menuItems} />
        </Header>
        <Content style={{ padding: '20px' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/version1" element={<Version1 />} />
            <Route path="/version2" element={<Version2 />} />
            <Route path="/notify" element={<Notify />} />
          </Routes>
        </Content>
      </Layout>
    </Router>
  );
}

export default App;
