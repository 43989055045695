// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { message } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Register the service worker with callbacks
serviceWorkerRegistration.register({
  onUpdate: registration => {
    // Notify the user about the update and reload the page
    message.info('New version available! Refreshing the page to update.', 5);
    // Send a message to the waiting service worker to skip waiting
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    // Reload the page to activate the new service worker
    window.location.reload();
  },
  onSuccess: registration => {
    console.log('Service worker registered successfully:', registration);
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
