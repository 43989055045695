import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

function Home() {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Welcome to Mappy</h1>
      <p>Select a version to proceed:</p>
      <Button type="primary" style={{ margin: '10px' }}>
        <Link to="/version1">Version 1 (With Extract Columns Button)</Link>
      </Button>
      <Button type="primary" style={{ margin: '10px' }}>
        <Link to="/version2">Version 2 (Enhanced UI Reactivity)</Link>
      </Button>
    </div>
  );
}

export default Home;
