// src/components/MappyV1.jsx
import React, { useEffect, useMemo, useState } from 'react';
import {
  Table,
  Button,
  Select,
  Upload,
  Divider,
  InputNumber,
  Spin,
  Alert,
  Tooltip,
  Collapse,
  Modal,
} from 'antd';
import {
  UploadOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import useMappy from '../hooks/useMappy';
import * as XLSX from 'xlsx';

const { Option } = Select;
const { Panel } = Collapse;

function MappyV1() {
  const {
    data,
    headerRow,
    handleHeaderRowChange,
    headerRowError,
    startRow,
    handleStartRowChange,
    startRowError,
    columnsOptions,
    selectedColumns,
    setSelectedColumns,
    isSortVisible,
    sortColumn,
    sortOrder,
    reorderedAddresses,
    addressValidation,
    zones,
    uploadedFileName,
    isLoading,
    isValidating,
    isSubmitting,
    handleReset,
    handleFileChange,
    handleSortColumnChange,
    handleSortOrderChange,
    extractAndValidateAddresses,
    handleOnDragEnd,
    handleMoveUp,
    handleMoveDown,
    handleRemoveAddress,
    handleSubmit,
    setReorderedAddresses,
  } = useMappy({ autoExtract: false }); // Set autoExtract to false for Version 1

  const [addressesCollapsed, setAddressesCollapsed] = useState(false);

  useEffect(() => {
    if (reorderedAddresses.length > 0 && sortColumn) {
      const colIndex = XLSX.utils.decode_col(sortColumn.trim().toUpperCase());
      const sortedAddresses = [...reorderedAddresses].sort((a, b) => {
        const aValue = a.row[colIndex] || '';
        const bValue = b.row[colIndex] || '';
        return sortOrder === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      });
      setReorderedAddresses(sortedAddresses);
    }
  }, [sortColumn, sortOrder, setReorderedAddresses, reorderedAddresses]);

  useEffect(() => {
    if (zones.length > 0) {
      setAddressesCollapsed(true);
    }
  }, [zones]);

  // Compute excel preview
  const excelPreview = useMemo(() => {
    if (data.length === 0) return [];
    const previewRows = 5; // Number of rows to preview
    return data.slice(0, previewRows).map((row, index) => {
      const rowNumber = index + 1;
      const rowData = {};
      for (let i = 0; i < row.length; i++) {
        rowData[String.fromCharCode(65 + i)] = row[i];
      }
      return { key: rowNumber, rowNumber, ...rowData };
    });
  }, [data]);

  // Confirmation before resetting
  const confirmReset = () => {
    Modal.confirm({
      title: 'Are you sure you want to reset?',
      content: 'All current data and settings will be lost.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: handleReset,
    });
  };

  // Available columns options excluding selected ones
  const availableColumnsOptions = columnsOptions.filter(
    (option) => !selectedColumns.includes(option.value)
  );

  // Render the Table for extracted addresses
  const renderTable = () => (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="addresses">
        {(provided) => (
          <Table
            {...provided.droppableProps}
            dataSource={reorderedAddresses}
            rowKey="id"
            pagination={false}
            ref={provided.innerRef}
            bordered
          >
            <Table.Column
              title="#"
              key="index"
              render={(text, record, index) => index + 1}
              width={50}
            />
            <Table.Column title="Address" dataIndex="address" key="address" />
            <Table.Column
              title="Validation"
              key="validation"
              render={(text, record) => {
                const validation = addressValidation[record.id];
                if (validation === undefined) {
                  return <Spin size="small" />;
                }
                return validation ? (
                  <span style={{ color: 'green' }}>✅ Valid</span>
                ) : (
                  <span style={{ color: 'red' }}>❌ Invalid</span>
                );
              }}
            />
            <Table.Column
              title="Actions"
              key="actions"
              width={150}
              render={(text, record, index) => (
                <span>
                  <Tooltip title="Move Up">
                    <Button
                      icon={<ArrowUpOutlined />}
                      disabled={index === 0}
                      onClick={() => handleMoveUp(index)}
                      style={{ marginRight: '5px' }}
                    />
                  </Tooltip>
                  <Tooltip title="Move Down">
                    <Button
                      icon={<ArrowDownOutlined />}
                      disabled={index === reorderedAddresses.length - 1}
                      onClick={() => handleMoveDown(index)}
                      style={{ marginRight: '5px' }}
                    />
                  </Tooltip>
                  <Tooltip title="Remove Address">
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveAddress(index)}
                      danger
                    />
                  </Tooltip>
                </span>
              )}
            />
          </Table>
        )}
      </Droppable>
    </DragDropContext>
  );

  // Render the Buttons for Submit and Reset
  const renderButtons = () => (
    <>
      <Button
        type="primary"
        onClick={handleSubmit}
        style={{ marginTop: '20px' }}
        disabled={reorderedAddresses.length === 0 || isSubmitting}
      >
        {isSubmitting ? <Spin size="small" /> : 'Submit'}
      </Button>

      <Button
        onClick={confirmReset}
        style={{ marginLeft: '10px', marginTop: '20px' }}
      >
        Reset
      </Button>
    </>
  );

  return (
    <div>
      <h2>Mappy Version 1</h2>

      <h3>Excel File</h3>
      <Upload
        accept=".xlsx, .xls"
        beforeUpload={(file) => {
          handleFileChange(file);
          return false;
        }}
        showUploadList={false}
        aria-label="Upload Excel File"
      >
        <Button icon={<UploadOutlined />} aria-label="Select Excel File">
          Select Excel File
        </Button>
      </Upload>

      {/* Display the uploaded file name */}
      {uploadedFileName && (
        <div style={{ marginTop: '10px', fontStyle: 'italic', color: '#555' }}>
          <strong>Uploaded File:</strong> {uploadedFileName}
        </div>
      )}

      {isLoading && (
        <div style={{ marginTop: '20px' }}>
          <Spin tip="Loading Excel File..." />
        </div>
      )}

      {/* Display settings only after file is uploaded */}
      {data.length > 0 && (
        <>
          <Divider />
          <h3>Settings</h3>
          {/* Set Header Row */}
          <div style={{ marginTop: '20px' }}>
            <label htmlFor="headerRowInput" style={{ marginRight: '10px' }}>
              Header Row:
            </label>
            <InputNumber
              id="headerRowInput"
              value={headerRow}
              min={0}
              onChange={handleHeaderRowChange}
              style={{ width: '80px' }}
              placeholder="0"
              aria-describedby="headerRowHelp"
            />
            <Tooltip title="Enter the row number where headers are located. Set to 0 if there's no header row.">
              <InfoCircleOutlined style={{ marginLeft: '8px', color: '#1890ff' }} aria-label="Header Row Information" />
            </Tooltip>
            {headerRowError && (
              <Alert
                message={headerRowError}
                type="error"
                showIcon
                style={{ marginTop: '5px' }}
                role="alert"
              />
            )}
          </div>

          {/* Set Start Row */}
          <div style={{ marginTop: '20px' }}>
            <label htmlFor="startRowInput" style={{ marginRight: '10px' }}>
              Start Data Row:
            </label>
            <InputNumber
              id="startRowInput"
              value={startRow}
              min={1}
              onChange={handleStartRowChange}
              style={{ width: '80px' }}
              placeholder="1"
              aria-describedby="startRowHelp"
            />
            <Tooltip title="Enter the row number where data starts. Must be greater than Header Row.">
              <InfoCircleOutlined style={{ marginLeft: '8px', color: '#1890ff' }} aria-label="Start Data Row Information" />
            </Tooltip>
            {startRowError && (
              <Alert
                message={startRowError}
                type="error"
                showIcon
                style={{ marginTop: '5px' }}
                role="alert"
              />
            )}
          </div>

          {/* Display column selection only after headerRow is set */}
          {headerRow !== null && (
            <div style={{ marginTop: '20px' }}>
              <label htmlFor="columnsSelect" style={{ marginRight: '10px' }}>
                Select columns for addresses:
              </label>
              <Select
                id="columnsSelect"
                mode="multiple"
                value={selectedColumns}
                onChange={setSelectedColumns}
                style={{ width: '350px', marginTop: '5px' }}
                placeholder="Select columns"
                options={availableColumnsOptions}
                showSearch
                optionFilterProp="label"
                aria-describedby="columnsSelectHelp"
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />
              {selectedColumns.length === 0 && (
                <Alert
                  message="Please select at least one column."
                  type="warning"
                  showIcon
                  style={{ marginTop: '5px' }}
                  role="alert"
                />
              )}
            </div>
          )}

          {isSortVisible && (
            <>
              <div style={{ marginTop: '20px' }}>
                <label style={{ marginRight: '10px' }}>Order by column:</label>
                <Select
                  value={sortColumn}
                  onChange={handleSortColumnChange}
                  style={{ width: '200px' }}
                  placeholder="Select column"
                  options={columnsOptions}
                  showSearch
                  optionFilterProp="label"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </div>

              {sortColumn && (
                <div style={{ marginTop: '20px' }}>
                  <label style={{ marginRight: '10px' }}>Sort Direction:</label>
                  <Select
                    value={sortOrder}
                    onChange={handleSortOrderChange}
                    style={{ width: '200px' }}
                    placeholder="Select direction"
                  >
                    <Option value="asc">Ascending</Option>
                    <Option value="desc">Descending</Option>
                  </Select>
                </div>
              )}
            </>
          )}

          <Button
            type="primary"
            onClick={extractAndValidateAddresses}
            disabled={selectedColumns.length === 0 || headerRowError || startRowError || isValidating}
            style={{ marginTop: '20px' }}
          >
            {isValidating ? <Spin size="small" /> : 'Extract Columns'}
          </Button>
        </>
      )}

      {/* Data Preview */}
      {data.length > 0 && (reorderedAddresses.length === 0 || selectedColumns.length === 0) && (
        <>
          <Divider />
          <h3>
            Excel Preview
            <Tooltip title={`First ${excelPreview.length} Rows From Excel File`}>
              <InfoCircleOutlined style={{ marginLeft: '8px', color: '#1890ff' }} aria-label="Excel Preview Information" />
            </Tooltip>
          </h3>

          <div style={{ overflowX: 'auto', border: '1px solid #e8e8e8' }}>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr>
                  <th style={excelRowNumberHeaderStyle}>Row</th>
                  {excelPreview[0] &&
                    Object.keys(excelPreview[0])
                      .filter((key) => key !== 'key' && key !== 'rowNumber')
                      .map((col, index) => (
                        <th key={`header-${index}`} style={excelHeaderStyle}>
                          {col}
                        </th>
                      ))}
                </tr>
              </thead>
              <tbody>
                {excelPreview.map((rowData, rowIndex) => (
                  <tr key={`row-${rowIndex}`}>
                    <td style={excelRowNumberCellStyle}>{rowData.rowNumber}</td>
                    {Object.keys(rowData)
                      .filter((key) => key !== 'key' && key !== 'rowNumber')
                      .map((col, colIndex) => (
                        <td key={`cell-${rowIndex}-${colIndex}`} style={excelCellStyle}>
                          {rowData[col] || ''}
                        </td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {/* Display extracted and validated addresses */}
      {reorderedAddresses.length > 0 && selectedColumns.length > 0 && (
        <>
          <Divider />

          {!addressesCollapsed ? (
            // Display section with a <h3> title
            <div>
              <h3>Extracted Addresses</h3>
              {renderTable()}
              {renderButtons()}
            </div>
          ) : (
            // Display section in a collapsible panel
            <Collapse
              activeKey={addressesCollapsed ? [] : ["addresses"]}
              onChange={(key) => {
                if (key.length === 0) {
                  setAddressesCollapsed(true); // Collapse the panel
                } else {
                  setAddressesCollapsed(false); // Expand the panel
                }
              }}
            >
              <Panel header="Extracted Addresses" key="addresses">
                {renderTable()}
                {renderButtons()}
              </Panel>
            </Collapse>
          )}
        </>
      )}

      {/* Zones Display */}
      {zones.length > 0 && (
        <>
          <Divider />
          <h3>Zones</h3>
          <Collapse accordion>
            {zones.map((zone) => (
              <Panel header={`Zone ${zone.zoneNumber}`} key={zone.id}>
                <Table
                  dataSource={zone.addresses.map((addr, idx) => ({ key: idx, address: addr }))}
                  pagination={false}
                  bordered
                  size="small"
                  style={{ marginBottom: '10px' }}
                  aria-label={`Zone ${zone.zoneNumber} Addresses`}
                >
                  <Table.Column
                    title="#"
                    dataIndex="key"
                    key="key"
                    render={(text, record, index) => index + 1}
                    width={50}
                  />
                  <Table.Column title="Address" dataIndex="address" key="address" />
                </Table>
                <div>
                  <strong>Zone URL:</strong>{' '}
                  <a href={zone.shortUrl} target="_blank" rel="noopener noreferrer">
                    {zone.shortUrl}
                  </a>
                </div>
              </Panel>
            ))}
          </Collapse>
        </>
      )}
    </div>
  );
}

// Styles for Excel-like table
const excelHeaderStyle = {
  border: '1px solid #d9d9d9',
  backgroundColor: '#f0f0f0',
  padding: '8px',
  textAlign: 'center',
  fontWeight: 'bold',
};

const excelCellStyle = {
  border: '1px solid #d9d9d9',
  padding: '8px',
  textAlign: 'left',
};

const excelRowNumberHeaderStyle = {
  ...excelHeaderStyle,
  width: '50px',
};

const excelRowNumberCellStyle = {
  ...excelCellStyle,
  width: '50px',
};

export default MappyV1;
